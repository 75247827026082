:root {
  --primary-color: #2d96ce;
}

.gradientPage {
  padding-top: 30px;
  background-color: #191c20; /*this your primary color*/
  width: 100%;
  height: 2000;
  border-top: 3px solid #2d96ce;
}

.footerPerm{
  position: fixed;
  bottom:0;
  left: 0;
  background-color: #424d5e;
}

.contentPage {
  color: white;
  width: 100%;
  height: 100vh;
  margin: auto;
  font-family: "Share Tech Mono";
}

.customLink{
  font-family: "Share Tech Mono";
  font-size: 36;
  color: var(--primary-color);
  transition: all 0.35s ease-in-out;
  text-decoration: none;
}
.customLink:hover {
  transform: scale(1.1);
  text-decoration: underline;
}

.customNavItem {
  font-family: "Share Tech Mono";
  font-size: 36;
  color: white;
  transition: all 0.35s ease-in-out;
  text-decoration: none;
}
.customNavItem:hover {
  color: var(--primary-color);
  transform: scale(1.1);
  text-decoration: underline;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
