body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal-body{
  background-color: #191c20 !important;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
  color: white;
}

.modal-header{
  background-color: #10101c !important;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
  color: white;
  border-bottom: 3px solid #2d96ce !important;
}

.modal-content{
  background-color: #000 !important; 
}

.gameClickableImage {
  border: 3px solid #2d96ce00 !important;
  border-radius: 0.5rem !important;
  transition: all 0.2s ease-in-out;
}
.gameClickableImage:hover {
  border: 3px solid #2d96ce !important;
  transform: scale(1.075);
}